import scroll from "scroll"
import scrollDoc from "scroll-doc"

const isInViewport = (el, offset) => {
  const rect = el.getBoundingClientRect()
  return Math.floor(rect.top) <= offset + offset / 2
}

const scrollToElement = (element_id, offset = 125) => {
  const page = scrollDoc()
  const element = document.querySelector(element_id)
  if (element) {
    scroll.top(page, element.offsetTop - offset, function (err) {
      const is_in_view_port = isInViewport(element, offset)
      // If the node is empty, It means the element data hasn't been ready in Redux.
      // So fire scrollToElement again with longer delay
      const is_empty_node = !element.hasChildNodes()
      const has_error =
        err && err?.message != "Element already at target scroll position"
      let timeoutMS = !has_error && is_in_view_port && is_empty_node ? 500 : 50
      if (!has_error && (!is_in_view_port || is_empty_node)) {
        setTimeout(() => scrollToElement(element_id, offset), timeoutMS)
      }
    })
  }
}

export default scrollToElement
