import { Box, Image, Picture } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import { resizeImg } from "helpers/image"
import { getUrlProps } from "helpers/landing"

const LandingImage = ({
  alt,
  containerStyle,
  firstElement,
  image,
  lazyload,
}) => {
  let picture = null

  if (image.src) {
    picture = (
      <Image
        alt={alt || image.alt || image.title || ""}
        fallback={image.fallback}
        height={image.src.height || image.height}
        lazyload={lazyload}
        sizes={image.src.sizes || image.sizes}
        src={image.src.src || image.src}
        srcSet={image.src.imgSrcSet || image.imgSrcSet}
        title={image.title}
        width={image.src.width || image.width}
        {...image.style}
      />
    )
  } else {
    let srcSet = []
    if (!image.srcSet) {
      srcSet = [
        {
          media: "(max-width: 767px)",
          sizes: "100vw",
          srcSet: `
            ${resizeImg(image.mobile, { width: 767 })} 767w,
            ${resizeImg(image.mobile_2x, { width: 1534 })} 1534w
          `,
        },
        {
          media: `(min-width: ${image.tablet ? "1024" : "768"}px)`,
          sizes: "100vw",
          srcSet: `
            ${resizeImg(image.desktop, {
              width: 1920,
            })} 1x,
            ${resizeImg(image.desktop_2x, {
              width: 3840,
            })} 2x
          `,
        },
      ]
      if (image.tablet) {
        srcSet.splice(1, 0, {
          media: "(min-width: 768px) and (max-width: 1023px)",
          sizes: "100vw",
          srcSet: `
            ${resizeImg(image.tablet, {
              width: 1024,
            })} 1x,
            ${resizeImg(image.tablet_2x, {
              width: 2048,
            })} 2x
          `,
        })
      }
    }
    // OLD remove above after import

    picture = (
      <Picture
        alt={alt || image.alt || image.title || ""}
        fallback={
          image.fallback || resizeImg(image.desktop_2x, { width: 3840 })
        }
        lazyload={lazyload}
        srcSet={image.srcSet || srcSet}
        title={image.title}
        {...image.style}
      />
    )
  }

  const urlProps = getUrlProps(image?.image_url)

  return (
    <Box as={firstElement ? "h1" : "div"} m={0} {...containerStyle}>
      {image.image_url ? <Box {...urlProps}>{picture}</Box> : picture}
    </Box>
  )
}

LandingImage.propTypes = {
  alt: PropTypes.string,
  containerStyle: PropTypes.object,
  firstElement: PropTypes.bool,
  image: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default LandingImage
