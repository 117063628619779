import { Button } from "@jewlr/storybook/core"
import { Link } from "react-router-dom"

import { logCurrentPageInCollectionStats } from "./collection"
import scrollToElement from "./scroll-to-element"

export const getUrlProps = (url, location, linkClickData, onClick = null) => {
  if (!url) return {}

  const { pathname } = location || {}

  if (url.startsWith("http")) {
    return {
      as: "a",
      "data-lc": linkClickData,
      display: "block",
      href: url,
      onClick: (e) => {
        e.stopPropagation()
        if (pathname) {
          logCurrentPageInCollectionStats(pathname)
        }
        if (onClick) {
          onClick()
        }
      },
    }
  } else if (url.startsWith("#")) {
    return {
      "data-lc": linkClickData,
      forwardedAs: Button,
      onClick: (e) => {
        e.stopPropagation()
        scrollToElement(url)
        if (pathname) {
          logCurrentPageInCollectionStats(pathname)
        }
        if (onClick) {
          onClick()
        }
      },
    }
  }

  const path = !url.startsWith("/") ? `/${url}` : url
  return {
    as: Link,
    "data-lc": linkClickData,
    display: "block",
    onClick: (e) => {
      e.stopPropagation()
      if (pathname) {
        logCurrentPageInCollectionStats(pathname)
      }
      if (onClick) {
        onClick()
      }
    },
    to: path,
  }
}
