import { MediaBox, Video } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React from "react"

import { retry } from "helpers/application"

const CarouselFiltered = loadable(() =>
  retry(() => import("components/landing/carousel-filtered"))
)
const CustomerReviews = loadable(() =>
  retry(() => import("components/landing/customer-reviews"))
)
const FeatureGrid = loadable(() =>
  retry(() => import("components/landing/feature-grid"))
)
const FlexBlock = loadable(() =>
  retry(() => import("components/landing/flex-block"))
)
const ImageBackgroundBanner = loadable(() =>
  retry(() => import("components/landing/image-background-banner"))
)
const ImageCenteredBox = loadable(() =>
  retry(() => import("components/landing/image-centered-box"))
)
const ImageTextBlock = loadable(() =>
  retry(() => import("components/landing/image-text-block"))
)
const Row = loadable(() => retry(() => import("components/landing/row")))
const Textarea = loadable(() =>
  retry(() => import("components/landing/textarea"))
)
const LandingHype = loadable(() => retry(() => import("components/HypeBanner")))

const LandingCarousel = loadable(() =>
  retry(() => import("./elements/landing-carousel"))
)
const LandingCarouselBlock = loadable(() =>
  retry(() => import("./elements/landing-carousel-block"))
)
const LandingCategories = loadable(() =>
  retry(() => import("./elements/landing-categories"))
)
const LandingDivider = loadable(() =>
  retry(() => import("./elements/landing-divider"))
)
const LandingHorizontalProducts = loadable(() =>
  retry(() => import("./elements/landing-horizontal-products"))
)
const LandingImage = loadable(() =>
  retry(() => import("./elements/landing-image"))
)
const LandingMargin = loadable(() =>
  retry(() => import("./elements/landing-margin"))
)
const LandingMenu = loadable(() =>
  retry(() => import("./elements/landing-menu"))
)
const LandingSlider = loadable(() =>
  retry(() => import("./elements/landing-slider"))
)
const LandingVerticalProducts = loadable(() =>
  retry(() => import("./elements/landing-vertical-products"))
)
const LandingYotpoGallery = loadable(() =>
  retry(() => import("./elements/landing-yotpo-gallery"))
)
const ProductCollection = loadable(() =>
  retry(() => import("./elements/product-collection"))
)
const LandingPerks = loadable(() =>
  retry(() => import("./elements/landing-perks"))
)
const LandingCollectionMenu = loadable(() =>
  retry(() => import("./elements/landing-collection-menu"))
)
const LandingCollage = loadable(() =>
  retry(() => import("./elements/landing-collage"))
)
const LandingHeroBanner = loadable(() =>
  retry(() => import("./elements/hero-banner"))
)
const LandingContentFeature = loadable(() =>
  retry(() => import("./elements/landing-content-feature"))
)
const LandingCardGrid = loadable(() =>
  retry(() => import("./elements/landing-card-grid"))
)
const LandingFeatureImageBox = loadable(() =>
  retry(() => import("./elements/landing-feature-image-box"))
)
const LandingImageRow = loadable(() =>
  retry(() => import("./elements/image-row"))
)
const LandingPackaging = loadable(() =>
  retry(() => import("./elements/landing-packaging"))
)
const LandingGiftsFeature = loadable(() =>
  retry(() => import("./elements/landing-gifts-feature"))
)
const LandingQuickLinks = loadable(() =>
  retry(() => import("./elements/landing-quick-links"))
)
const LandingAsSeenIn = loadable(() =>
  retry(() => import("./elements/landing-as-seen-in"))
)
const LandingMarquee = loadable(() =>
  retry(() => import("./elements/landing-marquee"))
)
const ShopTheLookCarousel = loadable(() =>
  retry(() => import("./elements/slider/shop-the-look-carousel"))
)
const LandingProductGrid = loadable(() =>
  retry(() => import("./elements/landing-product-grid"))
)
const LandingDaysOfDeals = loadable(() =>
  retry(() => import("./elements/days-of-deals"))
)

const LandingElement = ({ el, index, wrapTheChild = true }) => {
  if (
    el.country &&
    el.country !== "all" &&
    !el.country.includes(sl.config.site_country)
  )
    return null

  const lazyload = index > 0
  const firstElement = index === 0

  const elId = el.anchor_name || el.id

  let content = null

  switch (el.element_type) {
    case "hype":
      content = (
        <LandingHype
          hype_project_name={el.hype_project_name}
          lazyload={lazyload}
        />
      )
      break
    case "categories":
      content = <LandingCategories element={el} />
      break
    case "carousel":
      // OLD LANDING SPREADSHEET COMPONENT
      content = <LandingCarousel element={el} lazyload={lazyload} />
      break
    case "divider":
      content = <LandingDivider element={el} />
      break
    case "margin":
      content = <LandingMargin element={el} />
      break
    case "jpg":
    case "jpgx":
      content = <LandingImage element={el} lazyload={lazyload} />
      break
    case "collection":
    case "products":
      content = el.vertical ? (
        <LandingVerticalProducts element={el} lazyload={lazyload} />
      ) : (
        <LandingHorizontalProducts element={el} lazyload={lazyload} />
      )
      break
    case "slider":
      content = <LandingSlider element={el} lazyload={lazyload} />
      break
    case "product_collection":
      content = <ProductCollection element={el} lazyload={lazyload} />
      break
    case "image_background":
      content = (
        <ImageBackgroundBanner
          element={el}
          firstElement={firstElement}
          lazyload={lazyload}
        />
      )
      break
    case "image_block":
    case "image_column":
      content = (
        <ImageTextBlock
          element={el}
          firstElement={firstElement}
          lazyload={lazyload}
        />
      )
      break
    case "image_centered_textblock":
      content = (
        <ImageCenteredBox
          element={el}
          firstElement={firstElement}
          lazyload={lazyload}
        />
      )
      break
    case "textarea":
      content = (
        <Textarea
          element={el.textarea}
          firstElement={firstElement}
          lazyload={lazyload}
          linkClickData={el?.anchor_name || el?.id}
          style={el.textarea.style}
        />
      )
      break
    case "video":
      content = <Video {...el} lazyload={lazyload} />
      break
    case "row":
      content = <Row element={el} lazyload={lazyload} />
      break
    case "yotpo-gallery":
      content = <LandingYotpoGallery element={el} />
      break
    case "menu":
      content = <LandingMenu element={el} />
      break
    case "super_carousel":
      content = <CarouselFiltered element={el} lazyload={lazyload} />
      break
    case "flex":
      content = <FlexBlock element={el} />
      break
    case "carousel-block":
      content = <LandingCarouselBlock element={el} lazyload={lazyload} />
      break
    case "customer-reviews":
      content = <CustomerReviews element={el} />
      break
    case "perks":
      content = <LandingPerks element={el} lazyload={lazyload} />
      break
    case "feature-grid":
      content = <FeatureGrid element={el} lazyload={lazyload} />
      break
    case "collection-menu":
      content = <LandingCollectionMenu element={el} lazyload={lazyload} />
      break
    case "collage":
      content = <LandingCollage element={el} lazyload={lazyload} />
      break
    case "hero-banner":
      content = <LandingHeroBanner element={el} lazyload={lazyload} />
      break
    case "content-feature":
      content = <LandingContentFeature element={el} />
      break
    case "card-grid":
      content = <LandingCardGrid element={el} lazyload={lazyload} />
      break
    case "feature-image-box":
      content = <LandingFeatureImageBox element={el} lazyload={lazyload} />
      break
    case "image-row":
      content = <LandingImageRow element={el} lazyload={lazyload} />
      break
    case "packaging":
      content = <LandingPackaging element={el} lazyload={lazyload} />
      break
    case "gifts-feature":
      content = <LandingGiftsFeature element={el} lazyload={lazyload} />
      break
    case "quick-links":
      content = <LandingQuickLinks element={el} />
      break
    case "as-seen-in":
      content = <LandingAsSeenIn element={el} lazyload={lazyload} />
      break
    case "marquee":
      content = <LandingMarquee element={el} />
      break
    case "shop-the-look-carousel":
      content = <ShopTheLookCarousel element={el} />
      break
    case "product-grid":
      content = <LandingProductGrid element={el} lazyload={lazyload} />
      break
    case "days-of-deals":
      content = <LandingDaysOfDeals element={el} lazyload={lazyload} />
      break
  }

  if (el.responsive_breakpoint) {
    const breakpoint = el.responsive_breakpoint
    content = (
      <MediaBox
        {...(el.responsive_cloned
          ? { lessThan: breakpoint }
          : { greaterThanOrEqual: breakpoint })}
      >
        {content}
      </MediaBox>
    )
  }

  if (!wrapTheChild) {
    return content
  }

  return <div id={elId}>{content}</div>
}

LandingElement.propTypes = {
  el: PropTypes.object.isRequired,
  index: PropTypes.number,
  wrapTheChild: PropTypes.bool,
}

export default LandingElement
