import { MediaBox, Video } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import IntersectionWrapper from "components/common/intersection-wrapper"

const MediaContainer = styled(IntersectionWrapper)`
  ${(props) =>
    props.aspectRatio &&
    `
    aspect-ratio: ${props.aspectRatio};
    width: 100%;
  `}
`

const LandingResponsiveVideo = ({ id, lazyload, video }) => {
  if (!video) return null

  return video.srcsets.map((srcset, i) => {
    const aspectRatio = `${srcset.width} / ${srcset.height}`

    return (
      <MediaBox key={`${id}-${i}`} {...srcset.breakpoint}>
        <MediaContainer aspectRatio={aspectRatio}>
          <Video
            lazyload={lazyload}
            options={video.options}
            poster={srcset.poster}
            sources={srcset.srcset}
            style={video.style}
          />
        </MediaContainer>
      </MediaBox>
    )
  })
}

LandingResponsiveVideo.propTypes = {
  id: PropTypes.string,
  lazyload: PropTypes.bool,
  video: PropTypes.object,
}

export default LandingResponsiveVideo
